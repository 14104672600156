import React from "react"
import { graphql } from "gatsby"
import Gallery from "../components/gallery"

const GalleryPage = ({ data, pageContext }) => {
  const { name, options } = pageContext
  return (
    <div>
      <Gallery
        name={name}
        options={options}
        photos={data.allContentfulAsset.edges}
      />
    </div>
  )
}

export default GalleryPage

export const query = graphql`
  query PhotoQuery {
    allContentfulAsset(filter: { description: { eq: "food" } }) {
      edges {
        node {
          id
          description
          title
          fluid(maxWidth: 1920, quality: 80) {
            ...GatsbyContentfulFluid
          }
          file {
            details {
              image {
                height
                width
              }
            }
          }
        }
      }
    }
  }
`
